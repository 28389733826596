import React from 'react';
import {
  Link
} from 'react-router-dom';
import {
  ApplicationHeader,
  Logo
} from '../common';

import './error.scss';

const Error = () => {
  return (
    <div className="Error">
      <ApplicationHeader>
        <Link to={'/'}><Logo /></Link>
      </ApplicationHeader>
      <section className="Error-content">
        <h1>Hakemuksien näyttäminen epäonnistui</h1>
      </section>
    </div>
  )
}

export default Error;
