import u from 'updeep';
import {
  Comment
} from './models';
import {sortApplications} from './utils';
import findIndex from 'lodash/findIndex';
import snakeCase from 'lodash/snakeCase';
import includes from 'lodash/includes';
import {
  GET,
  POST,
  PUT,
  DELETE,
  PATCH
} from './crud';

/**
 * ACTION TYPES, could be separated to their own file
 */
export const GENERATE_REVIEWER_REPORT = 'GENERATE_REVIEWER_REPORT';
export const GET_ALL_APPLICATIONS = 'GET_ALL_APPLICATIONS';
export const GET_BUNDLE_ALTERNATIVES = 'GET_BUNDLE_ALTERNATIVES';
export const GET_APPLICATION_BY_ID = 'GET_APPLICATION_BY_ID';
export const GET_APPLICATION_COMMENTS = 'GET_APPLICATION_COMMENTS';
export const GET_APPLICATION_RATINGS = 'GET_APPLICATION_RATINGS';
export const GET_GRANTS = 'GET_GRANTS';
export const GET_LINKS_BY_APPLICATION = 'GET_LINKS_BY_APPLICATION';
export const SET_CURRENT_BUNDLE_ID = 'SET_CURRENT_BUNDLE_ID';
export const ADD_RATING = 'ADD_RATING';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const DELETE_BUNDLE = 'DELETE_BUNDLE';
export const UPDATE_RATING = 'UPDATE_RATING';
export const UPDATE_ORDER_OF_ALL_APPLICATIONS = 'UPDATE_ORDER_OF_ALL_APPLICATIONS';
export const FILTER_APPLICATIONS_BY_KEYWORD = 'FILTER_APPLICATIONS_BY_KEYWORD';
export const DISPLAY_ALL_APPLICATIONS_FROM_CURRENT_BUNDLE = 'DISPLAY_ALL_APPLICATIONS_FROM_CURRENT_BUNDLE';
export const RESET_CURRENT_APPLICATION = 'RESET_CURRENT_APPLICATION';
export const GET_ADMIN_BUNDLES = 'GET_ADMIN_BUNDLES';
export const UPDATE_BUNDLE_REVIEWERS = 'UPDATE_BUNDLE_REVIEWERS';
export const SCRAPE_BUNDLE = 'SCRAPE_BUNDLE';
export const UPDATE_ADMINISTRATORS = 'UPDATE_ADMINISTRATORS';
export const GET_BUNDLE_REVIEWERS = 'GET_BUNDLE_REVIEWERS';
export const GET_ADMINISTRATORS = 'GET_ADMINISTRATORS';
export const UPDATE_ALL_APPLICATIONS_LOADING = 'UPDATE_ALL_APPLICATIONS_LOADING';
export const CONTENT_LOADING_FINISHED_FOR_PRELOADING = 'CONTENT_LOADING_FINISHED_FOR_PRELOADING';
export const IS_ADMIN = 'IS_ADMIN';
export const SET_REVIEWABLE_MODE = 'SET_REVIEWABLE_MODE';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const SET_CURRENTLY_ACTIVE_FILTER_FOR_APPLICATIONS = 'SET_CURRENTLY_ACTIVE_FILTER_FOR_APPLICATIONS';


/**
 *  ACTIONS, could be separated to their own files and subfolders in this folder
 */

export const setCurrentlyActiveFilterForApplications = (filterObj) => {
  return dispatch => dispatch(setCurrentlyActiveFilterForApplicationsAction(filterObj));
}
const setCurrentlyActiveFilterForApplicationsAction = (filterObj) => ({
  type: SET_CURRENTLY_ACTIVE_FILTER_FOR_APPLICATIONS,
  payload: filterObj
})

const downloadFile = (blob, filename) => {
  if(blob.size === 0) { alert("Ei arviointeja"); return; }
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

export const generateReviewerReport = (startDate, endDate) => {
  return dispatch => {
    const data = JSON.stringify({
      startDate: startDate,
      endDate: endDate
    })

    POST(`/reviewerReport/?format=xlsx`, data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.blob();
    }).then(blob => {
      downloadFile(blob,`arvioijaraportti_alkaen_${startDate}_paattyen_${endDate}.xlsx`);
      dispatch(generateReviewerReportAction(data))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}

const generateReviewerReportAction = (data) => ({
  type: GENERATE_REVIEWER_REPORT,
  payload: data
})

export const setReviewableMode = (isReviewable, currentBundleId) => {
  return dispatch => {
    const data = JSON.stringify({
      reviewable: isReviewable
    })

    PUT(`/bundles/${currentBundleId}`, data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return dispatch(setReviewableModeAction(isReviewable));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const setReviewableModeAction = (isReviewable) => ({
  type: SET_REVIEWABLE_MODE,
  payload: isReviewable
})

export const updateAdministrators = (administrators) => {
  return dispatch => {
    const data = JSON.stringify({
      emails: administrators
    })

    POST('/adminGroup', data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return dispatch(updateAdministratorsAction(administrators));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const updateAdministratorsAction = (administrators) => ({
  type: UPDATE_ADMINISTRATORS,
  payload: administrators
})

export const scrapeBundle = (bundleID, sessionCookie, scrapingMode, ratingFields, singleApplicationID) => {
  return dispatch => {
    const ratingConf = {
      ratingFields: ratingFields
    }
    const data = JSON.stringify({
      sessionCookie: sessionCookie,
      scrapingMode: scrapingMode,
      ratingConf: ratingConf,
      applicationId: singleApplicationID
    })

    POST(`/scrapeBundle/${bundleID}`, data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return dispatch(scrapeBundleAction(response.headers.get('Location')));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const scrapeBundleAction = (url) => ({
  type: SCRAPE_BUNDLE,
  payload: url
})

export const updateBundleReviewers = (bundleReviewers, bundleSelection) => {
  return dispatch => {
    const data = JSON.stringify({
      emails: bundleReviewers
    })

    POST(`/bundles/${bundleSelection}/userGroup`, data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return dispatch(updateBundleReviewersAction(bundleReviewers));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const updateBundleReviewersAction = (ratingObject) => ({
  type: UPDATE_BUNDLE_REVIEWERS,
  payload: ratingObject
})

export const fetchAdminBundles = () => {
  return dispatch => {
    GET(`/bundles?isAdminView=Y`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if (!response.ok) { throw response; }
      return response.json();
    }).then(json => {
      return dispatch(fetchAdminBundlesAction(json))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const fetchAdminBundlesAction = (adminBundles) => ({
  type: GET_ADMIN_BUNDLES,
  payload: adminBundles
})

export const updateOrderOfAllApplications = (sortName, sortOrder) => {
  const sortConfig = {
    sortName,
    sortOrder
  }
  return dispatch => dispatch(updateOrderOfAllApplicationsAction(sortConfig));
}
const updateOrderOfAllApplicationsAction = (sortConfig) => ({
  type: UPDATE_ORDER_OF_ALL_APPLICATIONS,
  payload: sortConfig
})

export const displayAllApplicationsFromCurrentBundle = () => {
  return dispatch => dispatch(displayAllApplicationsFromCurrentBundleAction());
}
const displayAllApplicationsFromCurrentBundleAction = () => ({
  type: DISPLAY_ALL_APPLICATIONS_FROM_CURRENT_BUNDLE,
  payload: {}
})

export const filterApplicationsByKeyword = (keyword) => {
  return dispatch => {
    if (keyword === "") {
      return dispatch(displayAllApplicationsFromCurrentBundle())
    }
    return dispatch(filterApplicationsByKeywordAction(keyword));
  }
}
const filterApplicationsByKeywordAction = (keyword) => ({
  type: FILTER_APPLICATIONS_BY_KEYWORD,
  payload: keyword
})

export const deleteApplicationByID = (applicationID) => {
  return dispatch => {
    const data = JSON.stringify({
      id: applicationID
    })

    DELETE(`/applications/${applicationID}`, data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if (!response.ok) { throw response; }
      return
    }).then(() => {
      return dispatch(deleteApplicationAction(applicationID));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }

}

const deleteApplicationAction = (deletedApplicationID) => ({
  type: DELETE_APPLICATION,
  payload: deletedApplicationID
})

export const deleteBundleByID = (bundleID) => {
  return dispatch => {
    PATCH(`/bundles/${bundleID}/close`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if (!response.ok) { throw response; }
      return
    }).then(() => {
      return dispatch(deleteBundleAction(bundleID));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}

const deleteBundleAction = (deletedBundleID) => ({
  type: DELETE_BUNDLE,
  payload: deletedBundleID
})

export const deleteComment = (commentId) => {
  return dispatch => {
    const data = JSON.stringify({
      commentId: commentId
    })

    DELETE(`/comments/${commentId}`, data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if (!response.ok) { throw response; }
      return
    }).then(() => {
      return dispatch(deletedCommentAction(commentId));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}

const deletedCommentAction = (deletedCommentId) => ({
  type: DELETE_COMMENT,
  payload: deletedCommentId
})

export const addComment = (comment, applicationID) => {
  return dispatch => {
    const data = JSON.stringify({
      comment: comment,
      applicationId: applicationID
    })

    POST(`/comments`, data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if (!response.ok) { throw response; }
      return response.json();
    }).then(json => {
      const addedComment = new Comment(json.id, '', '', comment);
      dispatch(addCommentAction(addedComment))
      return dispatch(getApplicationComments(applicationID))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const addCommentAction = (addedComment) => ({
  type: ADD_COMMENT,
  payload: addedComment
})

export const addRating = (ratingObject) => {
  return dispatch => {
    const data = JSON.stringify({
      applicationId: ratingObject.applicationId,
      ratingFieldName: snakeCase(ratingObject.ratingFieldName),
      ratingFieldValue: ratingObject.ratingFieldValue,
    });
    POST('/ratings', data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if (!response.ok) { throw response }
      return response.json();
    }).then((json) => {
      const updatedRating = ratingObject;
      updatedRating.id = json.id
      return dispatch(addRatingAction(updatedRating));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const addRatingAction = (rating) => ({
  type: ADD_RATING,
  payload: rating
})

export const updateRating = (ratingObject) => {
  return dispatch => {
    const data = JSON.stringify({
      applicationId: ratingObject.applicationId,
      ratingFieldName: snakeCase(ratingObject.ratingFieldName),
      ratingFieldValue: ratingObject.ratingFieldValue
    })

    PUT(`/ratings/${ratingObject.ratingId}`, data).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return dispatch(updateRatingAction(ratingObject));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const updateRatingAction = (ratingObject) => ({
  type: UPDATE_RATING,
  payload: ratingObject
})

export const fetchAdministrators = () => {
  return dispatch => {
    GET(`/adminGroup`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then((json) => {
      return dispatch(fetchAdministratorsAction(json))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const fetchAdministratorsAction = (administrators) => ({
  type: GET_ADMINISTRATORS,
  payload: administrators
})

export const fetchBundleReviewers = (bundleSelection) => {
  return dispatch => {
    GET(`/bundles/${bundleSelection}/userGroup`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then((json) => {
      return dispatch(fetchBundleReviewersAction(json))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const fetchBundleReviewersAction = (bundleReviewers) => ({
  type: GET_BUNDLE_REVIEWERS,
  payload: bundleReviewers
})

export const getApplicationComments = (applicationID) => {
  return dispatch => {
    dispatch(contentLoaddingFinishedForPreloading(false));
    GET(`/comments?applicationId=${applicationID}`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then((json) => {
      dispatch(contentLoaddingFinishedForPreloading(true));
      return dispatch(getApplicationCommentsAction(json))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const getApplicationCommentsAction = (applicationComments) => ({
  type: GET_APPLICATION_COMMENTS,
  payload: applicationComments
})

export const resetCurrentApplication = () => {
  return dispatch => dispatch(resetCurrentApplicationAction())
}
const resetCurrentApplicationAction = () => ({
  type: RESET_CURRENT_APPLICATION,
  payload: {}
})

export const getApplicationRatings = (applicationID) => {
  return dispatch => {
    GET(`/ratings?applicationId=${applicationID}`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then((json) => {
      return dispatch(getApplicationRatingsAction(json))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const getApplicationRatingsAction = (applicationRatings) => ({
  type: GET_APPLICATION_RATINGS,
  payload: applicationRatings
})

export const getApplicationByID = (applicationID, DPI) => {
  return dispatch => {
    GET(`/applications/${applicationID}?dpi=${DPI}`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then((json) => {
      return dispatch(getApplicationByIDAction(json))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const getApplicationByIDAction = (currentApplication) => ({
  type: GET_APPLICATION_BY_ID,
  payload: currentApplication
})

export const setCurrentBundleId = (currentBundleId) => {
  return dispatch => {
    dispatch(fetchGrants(currentBundleId))
    dispatch(setCurrentBundleIdAction(currentBundleId))
  };
}
const setCurrentBundleIdAction = (currentBundleId) => ({
  type: SET_CURRENT_BUNDLE_ID,
  payload: currentBundleId
})

export const getBundleAlternatives = () => {
  return dispatch => {
    GET('/bundles').then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then((bundles) => {
      if (bundles && bundles.length > 0 && bundles[0].id) {
        bundles.reverse()
        const currentBundleId = bundles[0].id;
        dispatch(setCurrentBundleId(currentBundleId))
        dispatch(getAllApplicationsByBundleID(currentBundleId));
        return dispatch(getBundleAlternativesAction(bundles));
      } else {
        throw new Error('Something went wrong with parsing bundles')
      }
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const getBundleAlternativesAction = (bundles) => ({
  type: GET_BUNDLE_ALTERNATIVES,
  payload: bundles
})

export const contentLoaddingFinishedForPreloading = (loading) => {
  return dispatch => dispatch(contentLoaddingFinishedForPreloadingAction(loading));
}
const contentLoaddingFinishedForPreloadingAction = (loading) => ({
  type: CONTENT_LOADING_FINISHED_FOR_PRELOADING,
  payload: loading
})

export const updateAllApplicationsLoadingStatus = (loading) => {
  return dispatch => dispatch(updateAllApplicationsLoadingStatusAction(loading));
}
const updateAllApplicationsLoadingStatusAction = (loading) => ({
  type: UPDATE_ALL_APPLICATIONS_LOADING,
  payload: loading
})

export const getAllApplicationsByBundleID = (bundleID) => {
  return dispatch => {
    dispatch(updateAllApplicationsLoadingStatus(true));
    GET('/applicationHeaders/'+ bundleID).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then((applicationHeaders) => {
      dispatch(setCurrentBundleId(bundleID))
      dispatch(updateAllApplicationsLoadingStatus(false));
      return dispatch(getAllApplicationsByBundleIDSync(applicationHeaders))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}
const getAllApplicationsByBundleIDSync = (applicationHeaders) => ({
  type: GET_ALL_APPLICATIONS,
  payload: applicationHeaders
})

export const getLinksByApplicationID = (applicationID) => {
  return dispatch => {
    GET('/links/' + applicationID).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then(links => {
      return dispatch(getLinksByApplicationAction(links))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}

const getLinksByApplicationAction = (links) => ({
  type: GET_LINKS_BY_APPLICATION,
  payload: links
})

export const fetchGrants = (bundleID) => {
  return dispatch => {
    GET('/grants/'+ bundleID).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if(!response.ok) { throw response }
      return response.json();
    }).then(grants => {
      return dispatch(fetchGrantsAction(grants))
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
    });
  }
}

const fetchGrantsAction = (grants) => ({
  type: GET_GRANTS,
  payload: grants
})

export const isAdmin = () => {
  return dispatch => {
    GET(`/isAdmin`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if (!response.ok) { throw response; }
      return dispatch(isAdminAction(true));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
      return dispatch(isAdminAction(false));
    });
  }
}
const isAdminAction = (isAdminPayload) => ({
  type: IS_ADMIN,
  payload: isAdminPayload
})

export const isLoggedIn = () => {
  return dispatch => {
    GET(`/session`).then((response) => {
      if (response.status === 403) { window.location.href = '/v10/login'; return false; }
      if (!response.ok) { throw response; }
      return dispatch(isLoggedInAction(true));
    }).catch((error) => {
      console.log('Something went wrong in fetch', error);
      return dispatch(isLoggedInAction(false));
    });
  }
}
const isLoggedInAction = (isLoggedInPayload) => ({
  type: IS_LOGGED_IN,
  payload: isLoggedInPayload
})

const initialState = {
  bundleAlternatives: [],
  allApplications: [],
  allApplicationsOfBundle: [],
  allApplicationsLoading: false,
  loginDone: false,
  currentlyActiveFilter: {},
  isReviewable: undefined,
  roleCheckDone: false,
  contentLoadingFinishedForPreloading: false,
  session: undefined,
  grants: {},
  sortingOrderOfApplications: { // Sort in alphabetical order by default
    sortName: 'applicantName',
    sortOrder: 'asc'
  }
}

/**
 *
 *  REDUCER for all application stuff, could be separeted to it's own file
 */

const rootReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_ALL_APPLICATIONS:
      const applicationsSortedByPreviousSortOrder =
        sortApplications(action.payload, state.sortingOrderOfApplications.sortName, state.sortingOrderOfApplications.sortOrder);
      return {
        ...state,
        isReviewable: applicationsSortedByPreviousSortOrder[0].reviewable,
        allApplications: applicationsSortedByPreviousSortOrder,
        allApplicationsOfBundle: applicationsSortedByPreviousSortOrder
      }
    case UPDATE_ORDER_OF_ALL_APPLICATIONS:
      const reorderedApplications =
        sortApplications(state.allApplications, action.payload.sortName, action.payload.sortOrder);
      const reorderedAllApplicationsOfCurrentBundle =
        sortApplications(state.allApplicationsOfBundle, action.payload.sortName, action.payload.sortOrder)
      return {
        ...state,
        allApplications: reorderedApplications,
        allApplicationsOfBundle: reorderedAllApplicationsOfCurrentBundle,
        sortingOrderOfApplications: action.payload,
      }
    case GET_BUNDLE_ALTERNATIVES:
      return {
        ...state,
        bundleAlternatives: action.payload
      }
    case GET_ADMIN_BUNDLES:
      return {
        ...state,
        adminBundles: action.payload
      }
    case GET_LINKS_BY_APPLICATION:
      return  {
        ...state,
        currentApplication: u({ links: action.payload }, state.currentApplication)
      }
    case SET_CURRENT_BUNDLE_ID:
      return {
        ...state,
        currentBundleId: action.payload
      }
    case GET_APPLICATION_BY_ID:
      return {
        ...state,
        currentApplication: u({ ...action.payload }, state.currentApplication),
      }
    case GET_APPLICATION_COMMENTS:
      return {
        ...state,
        currentApplication: u({ comments: action.payload.reverse() }, state.currentApplication)
      }
    case GET_APPLICATION_RATINGS:
      return {
        ...state,
        currentApplication: u({ ratings: action.payload[0] }, state.currentApplication)
      }
    case RESET_CURRENT_APPLICATION:
      return {
        ...state,
        currentApplication: {}
      }
    case UPDATE_RATING:
      const updatedRating = action.payload;
      const applicationWithUpdatedRating = state.allApplications.filter((a) => Number(a.id) === Number(action.payload.applicationId))[0]
      const updatedApplications = state.allApplications;
      if (updatedRating.ratingFieldName === 'rating1') { // Update to main view only if main rating
        applicationWithUpdatedRating.rating = String(updatedRating.ratingFieldValue);
        const index = findIndex(state.allApplications, {id: String(action.payload.applicationId)});
        updatedApplications.splice(index, 1, applicationWithUpdatedRating);
      }
      return {
        ...state,
        currentApplication: u({ratings: {[updatedRating.ratingFieldName]: updatedRating.ratingFieldValue}}, state.currentApplication),
        allApplications: updatedApplications
      }
    case ADD_RATING:
      const addedRating = action.payload;
      const applicationWithAddedRating = state.allApplications.filter((a) => Number(a.id) === Number(action.payload.applicationId))[0];
      const updatedApplicationsAddRating = state.allApplications;
      if (addedRating.ratingFieldName === 'rating1') { // Update to main view only if main rating
        applicationWithAddedRating.rating = String(addedRating.ratingFieldValue);
        const index = findIndex(state.allApplications, {id: String(action.payload.applicationId)})
        updatedApplicationsAddRating.splice(index, 1, applicationWithAddedRating)
      }
      return {
        ...state,
        currentApplication: u({ratings: {[addedRating.ratingFieldName]: addedRating.ratingFieldValue, id: addedRating.id}}, state.currentApplication),
        allApplications: updatedApplicationsAddRating
      }
    case ADD_COMMENT:
      return {
         ...state,
        currentApplication: {
          ...state.currentApplication,
          comments: [...state.currentApplication.comments, action.payload]
        }
      }
    case DELETE_COMMENT:
      const commentsWithoutDeletedComment = state.currentApplication.comments.filter((comment) => {
        return comment.id !== action.payload;
      });
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          comments: commentsWithoutDeletedComment
        }
      }
    case FILTER_APPLICATIONS_BY_KEYWORD:
      const searchKeyword = action.payload.toString();
      const filteredApplications = state.allApplicationsOfBundle.filter((obj) => {
        if (includes(obj.applicantName.toLowerCase(), searchKeyword.toLowerCase())) {return obj}
        if (includes(obj.grantField.toLowerCase(), searchKeyword.toLowerCase())) {return obj}
        if (includes(obj.journalId.toLowerCase(), searchKeyword.toLowerCase())) {return obj}
        if (includes(obj.grantType.toLowerCase(), searchKeyword.toLowerCase())) {return obj}
        if (includes(obj.grantSize.toLowerCase(), searchKeyword.toLowerCase())) {return obj}
        if (obj.rating && includes([obj.rating.toString().toLowerCase()], searchKeyword.toLowerCase())) {return obj}
        return undefined;
      });
      return {
        ...state,
        allApplications: filteredApplications,
        searchKeyword: searchKeyword
      }
    case DISPLAY_ALL_APPLICATIONS_FROM_CURRENT_BUNDLE:
      return {
        ...state,
        allApplications: state.allApplicationsOfBundle
      }
    case UPDATE_BUNDLE_REVIEWERS:
      return {
        ...state
      }
    case GET_ADMINISTRATORS:
      return {
        ...state,
        administrators: action.payload
      }
    case GET_GRANTS:
      return {
        ...state,
        grants: action.payload
      }
    case GET_BUNDLE_REVIEWERS:
      return {
        ...state,
        bundleReviewers: action.payload
      }
    case UPDATE_ALL_APPLICATIONS_LOADING:
      return {
        ...state,
        allApplicationsLoading: action.payload
      }
    case CONTENT_LOADING_FINISHED_FOR_PRELOADING:
      return {
        ...state,
        contentLoadingFinishedForPreloading: action.payload
      }
    case IS_LOGGED_IN:
      return {
        ...state,
        loginDone: action.payload
      }
    case IS_ADMIN:
      return {
        ...state,
        roleCheckDone: action.payload
      }
    case DELETE_APPLICATION:
      return {
        ...state,
        recentlyDeleted: action.payload
      }
    case DELETE_BUNDLE:
      return {
        ...state,
        recentlyDeleted: action.payload
      }
    case SCRAPE_BUNDLE:
      return {
        ...state,
        scrapeBundleTrackingUrl: action.payload
      }
    case SET_REVIEWABLE_MODE:
      return {
        ...state,
        isReviewable: action.payload
      }
    case SET_CURRENTLY_ACTIVE_FILTER_FOR_APPLICATIONS:
      return {
        ...state,
        currentlyActiveFilter: action.payload
      }
    case GENERATE_REVIEWER_REPORT:
      return state
    default:
      return state;
  }
}

export default rootReducer;
