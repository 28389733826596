import React, { Component } from 'react';
import AdminPage from '../admin-page';
import {Spinner} from '../../common';
import './applications.scss';

const REMOVE_APPLICATION = "removeApplication"
const REMOVE_BUNDLE = "removeBundle"

export default class Removal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      removeType: '',
      removeID: ''
    }
  }

  componentDidMount() {
    this.props.isAdmin();
    this.setState({
      removeType: REMOVE_APPLICATION
    })
  }

  onChange = (e) => {
    // Because we named the inputs to match their corresponding values in state, it's easy to set the state
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  removeData = (e) => {
    e.preventDefault()
    const {
      removeType,
      removeID
    } = this.state;

    if (removeType === REMOVE_APPLICATION) {
      this.props.deleteApplicationByID(removeID)
    } else if (removeType === REMOVE_BUNDLE) {
      this.props.deleteBundleByID(removeID)
    }

    else return
  }

  render() {

    const {
      roleCheckDone,
      recentlyDeleted
    } = this.props;

    if (!roleCheckDone) {
      return(<Spinner/>);
    }

    return (
      <AdminPage pageTitle="Tietojen poistaminen" innerPage>
        <form onSubmit={this.removeData}>
          <div className="form-group">
            <fieldset>
              <legend>Poiston tyyppi</legend>
              <div>
                <label>
                  <input
                    onChange={this.onChange}
                    type="radio"
                    checked={this.state.removeType === REMOVE_APPLICATION }
                    name="removeType"
                    value={REMOVE_APPLICATION} /> Poista hakemus</label>
              </div>
              <div>
                <label>
                  <input
                    onChange={this.onChange}
                    type="radio"
                    checked={this.state.removeType === REMOVE_BUNDLE }
                    name="removeType"
                    value={REMOVE_BUNDLE} /> Poista koonti</label>
              </div>
            </fieldset>
          </div>
          {this.state.removeType === REMOVE_BUNDLE &&
          <div className="form-group">
            <label htmlFor="removeID">Poistettavan koonnin ID</label>
            <input name="removeID" onChange={this.onChange} type="text" className="form-control" id="removeID" placeholder="Koonnin ID" />
          </div>
          }
          {this.state.removeType === REMOVE_APPLICATION &&
          <div className="form-group">
            <label htmlFor="removeID">Poistettavan hakemuksen ID</label>
            <input name="removeID" onChange={this.onChange} type="text" className="form-control" id="removeID" placeholder="Hakemuksen ID" />
          </div>
          }
          <button type="submit" className="btn">Poista</button>
          {recentlyDeleted && (
            <div>
              <p className="success-info">Poistaminen onnistui ID:llä: {recentlyDeleted}. Päivitä sivu.</p>
            </div>
          )}
        </form>
      </AdminPage>
    )
  }
}
