import React, { Component } from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import Rodal from 'rodal';
import { TaikeTable } from '../common';
import { TableHeaderColumn } from 'react-bootstrap-table';
import StarRating from 'react-star-rating-component';
import { array } from 'prop-types';
import { API } from '../crud';
import {
  ApplicationHeader,
  Logo,
  Spinner
} from '../common';
import {debounce} from 'lodash';
import 'core-js'

import 'rodal/lib/rodal.css'; // for Rodal modal
import './application-list.scss';

class ApplicationList extends Component {
  static propTypes = {
    allApplications: array.isRequired
  }

  constructor(props) {
    super(props);
    this.allApplications = this.props.allApplications;
    this.state = {
      data: this.allApplications,
      modalVisible: false
    };
  }

  show() {
    this.setState({ modalVisible: true });
  }

  hide() {
    this.setState({ modalVisible: false });
  }

  componentDidMount() {
    this.props.isLoggedIn();
    this.props.setCurrentlyActiveFilterForApplications({})
    if (this.props.history.action === 'PUSH') { // If we are returning from single application view, reset ApplicationList
      this.onSortChange('applicantName', 'asc') // Sort in alphabetical order by default
      this.props.displayAllApplicationsFromCurrentBundle()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.allApplications !== this.props.allApplications) {
      this.allApplications = nextProps.allApplications;
      this.setState ({
        data: this.allApplications
      });
      return true
    }
    if (this.props.currentBundleId !== nextProps.currentBundleId) {
      return true
    }
    if (this.props.isReviewable !== nextProps.isReviewable) {
      return true
    }
    if (this.props.grants !== nextProps.grants) {
      return true
    }
  }

  onSortChange = (sortName, sortOrder) => {
    this.props.updateOrderOfAllApplications(sortName, sortOrder);
    this.setState({
      data: this.allApplications
    });
  }

  onRowClick = (applicationRow) => {
    this.props.history.push(`/applications/${applicationRow.id}`);
  }

  onSearchChange = (searchText) => {
    this.props.filterApplicationsByKeyword(searchText);
    this.setState({
      data: this.allApplications
    });
  }

  filterNumber(targetVal, filterVal, comparator) {
    let valid = true;
    switch (comparator) {
      case '=': {
        if (targetVal !== filterVal) {
          valid = false;
        }
        break;
      }
      case '>': {
        if (targetVal <= filterVal) {
          valid = false;
        }
        break;
      }
      case '<=': {
        if (targetVal > filterVal) {
          valid = false;
        }
        break;
      }
      default: {
        console.error('Number comparator provided is not supported');
        break;
      }
    }
    return valid;
  }

  filterText(targetVal, filterVal) {
    if (targetVal.toString().toLowerCase() === filterVal.toString().toLowerCase()) {
      return true;
    }

    return false;
  }


  onFilterChange = (filterObj) => {
    if (Object.keys(filterObj).length === 0) {
      this.setState({
        data: this.allApplications
      });
      return;
    }

    // Disclaimer: this should be refactored...
    const data = this.allApplications.filter((application) => {
      let valid = true;
      let filterValue;
      for (const key in filterObj) {
        const targetValue = application[key];
        switch (filterObj[key].type) {
        case 'NumberFilter': {
          filterValue = filterObj[key].value.number;
          valid = this.filterNumber(targetValue, filterValue, filterObj[key].value.comparator);
          break;
        }
        default: {
          filterValue = (typeof filterObj[key].value === 'string') ?
            filterObj[key].value.toLowerCase() : filterObj[key].value;
          valid = this.filterText(targetValue, filterValue);
          break;
        }
        }

        if (!valid) {
          break;
        }
      }
      return valid;
    });

    this.props.setCurrentlyActiveFilterForApplications(filterObj)
    this.setState({
      data: data
    });
  }

  toggleReviewableMode = (newValue) => {
    this.props.setReviewableMode(newValue, this.props.currentBundleId);
  }

  render() {

    const {
      bundleAlternatives = [],
      onBundleChange = console.log('Missing'),
      currentBundleId,
      allApplicationsLoading,
      loginDone,
      isReviewable,
      grants
    } = this.props;

    const {
      data,
      modalVisible
    } = this.state;

    if (!loginDone) {
      return(<Spinner/>);
    }

    return (
      <div className="Application-list">
        <ApplicationHeader>
          <Link to={'/'}><Logo /></Link>
          <div onClick={this.show.bind(this)} className="help"><FontAwesome size='2x' title="Ohje" name="question-circle-o" /></div>
          <label className="bundle-alternatives-count" htmlFor="bundle-alternatives">Näytetään {data && data.length} hakemusta</label>
          <Reviewable isReviewable={isReviewable} toggleAction={this.toggleReviewableMode} />
          <div className="bundle-alternatives-container">
            { bundleAlternatives && bundleAlternatives.length > 0 ?
              <div>
                <select value={currentBundleId} id="bundle-alternatives" onChange={onBundleChange} className="form-control bundle-alternatives">
                  { bundleAlternatives.map((bundle, index) => (
                    <option key={index} value={bundle.id}>{bundle.name} ({bundle.status}), {bundle.id}</option>
                  ))}
                </select>
              </div>
            : <span title="Yhtään koontia ei löytynyt"><FontAwesome name="question-circle" />&nbsp;Koonteja ei löytynyt</span> }
          </div>
          </ApplicationHeader>
          <div className="table-container">
          { currentBundleId && <a download target="_blank" rel="noopener noreferrer" className="btn btn-link btn-success btn-download-excel" href={API(`/reviewerRatingsReport/${currentBundleId}?format=xlsx`)}>Lataa Excel arvioistasi</a> }
          { allApplicationsLoading ? <Spinner/> :
            <RemoteSorting
            grants={ grants }
            currentBundleId={ currentBundleId }
            onRowClick={ this.onRowClick }
            onSortChange={ this.onSortChange }
            onSearchChange={ this.onSearchChange }
            onFilterChange={ this.onFilterChange }
            { ...this.state } />
          }
        </div>
        <Rodal visible={modalVisible} onClose={this.hide.bind(this)} className="rodal-modal">
          <h2>Ohje</h2>
          <p>Ongelmatilanteissa ota yhteyttä sähköpostiosoitteeseen <strong>asiointi@taike.fi</strong></p>
          <p>Avaa oletussähköpostiohjelmallasi: <a href="mailto:asiointi@taike.fi">asiointi@taike.fi</a></p>
        </Rodal>
      </div>
    );
  }
}

const ReviewableLabel = ({isReviewable}) => isReviewable ?
  <span title="Voit ilmoittaa omat arviosi valmiiksi painamalla tästä"><FontAwesome name="circle-o" /> Tila: Arviointi kesken</span> :
  <span title="Olet merkinnyt arviosi valmiiksi"><FontAwesome name="check-circle-o" /> Tila: Arviointi valmis</span>

const Reviewable = ({isReviewable, toggleAction}) => {
  // Check that value was initialized from backend
  if (typeof(isReviewable) === "boolean") {
    return (
      <button className={`${isReviewable ? 'btn-is-reviewable' : 'btn-not-reviewable'} btn btn-reverse btn-reviewable`} onClick={() => toggleAction(!isReviewable)}>
        <ReviewableLabel isReviewable={isReviewable} />
      </button>
    )
  }
  return <button className="btn btn-reverse" disabled>Arvioinnin tila</button>;
}

class RemoteSorting extends Component {

  starFormatter = (ratingCell, row) => {

    const parsedRating = ratingCell ? parseInt(ratingCell, 10) : 0;
    return (
      <span>
        <StarRating
          className="star-rating-list"
          name={'rating-listing'} /* name of the radio input, it is required */
          value={parsedRating} /* number of selected icon (`0` - none, `1` - first) */
          starColor={'#54515A'} /* color of selected icons, default `#ffb400` */
          emptyStarColor={'#C1BFC4'} /* color of non-selected icons, default `#333` */
          editing={false} /* is component available for editing, default `true` */
        />
      </span>
    )
  }

  createCustomClearButton = (onClick) => {
    return (
      <button className="btn" onClick={ onClick }>Tyhjennä</button>
    );
  }

  render() {

    const {
      grants,
      data,
      onSortChange,
      onRowClick,
      onSearchChange,
      onFilterChange,
    } = this.props;

    const qualityType = Object.values(grants).reduce((final, grant) => {
      return {...final, [grant['grantField']]: grant['grantField'] }
    }, {})

    function enumFormatter(cell, row, enumObject) {
      return enumObject[cell];
    }

    const tableOptions = {
      onRowClick: onRowClick,
      onSortChange: debounce(onSortChange, 100),
      noDataText: 'Hakemuksia ei löytynyt',
      onSearchChange: debounce(onSearchChange, 1000),
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      onFilterChange: onFilterChange
    }

    return (
      <TaikeTable
        search={true}
        striped
        hover
        searchPlaceholder="Hae taulukon sisällön perusteella"
        data={data}
        remote={true}
        options={tableOptions}>
        <TableHeaderColumn dataField="id" isKey={true} hidden dataSort={true}>Hakemusnumero</TableHeaderColumn>
        <TableHeaderColumn dataField="applicantName" width="17.5%" dataSort={true}>Hakija</TableHeaderColumn>
        <TableHeaderColumn dataField="journalId" width="15%" dataSort={true}>Diaarinumero</TableHeaderColumn>
        <TableHeaderColumn className="grantField"
          dataField="grantField"
          filterFormatted
          dataFormat={ enumFormatter }
          formatExtraData={ qualityType }
          filter={{
            type: 'SelectFilter',
            options: qualityType,
            placeholder: 'Valitse'}}
          width="15%"
          dataSort={true}>Taiteenala</TableHeaderColumn>
        <TableHeaderColumn dataField="grantType" width="37.5%" dataSort={true}>Käyttötarkoitus</TableHeaderColumn>
        <TableHeaderColumn dataField="grantSize" width="15%" dataSort={true}>Haettu summa</TableHeaderColumn>
      </TaikeTable>
    );
  }
}

export default withRouter(ApplicationList);
