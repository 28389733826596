import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import AdminPage from './admin-page';
import {Spinner} from '../common';

import './admin-index.scss'

export default class AdminIndex extends Component {

  componentDidMount() {
    this.props.isAdmin();
  }

  render() {
    const {
      roleCheckDone
    } = this.props;

    if (!roleCheckDone) {
      return(<Spinner/>);
    }

    return (
      <AdminPage pageTitle="Hallintapaneeli">
        <section className="admin-index">
          <div className="admin-item"><Link to="/admin/reports"><FontAwesome name='line-chart' /> Raportit</Link></div>
          <div className="admin-item"><Link to="/admin/scrape"><FontAwesome name='wrench' /> Koontien käsittely</Link></div>
          <div className="admin-item"><Link to="/admin/applications"><FontAwesome name='file' /> Tietojen poistaminen</Link></div>
          <div className="admin-item"><Link to="/admin/reviewers"><FontAwesome name='users' /> Arvioijat</Link></div>
          <div className="admin-item"><Link to="/admin/users"><FontAwesome name='id-badge' /> Ylläpitäjät</Link></div>
        </section>
      </AdminPage>
    )
  }
}
