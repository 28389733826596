import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import { Provider } from 'react-redux';

import { createStore, applyMiddleware } from 'redux';
import rootReducer from './redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import registerServiceWorker from './registerServiceWorker';
import viewportUnitsBuggyFill from 'viewport-units-buggyfill';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css' // Icons
import './reset.css'; // Reset browsers
import './index.scss';

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

viewportUnitsBuggyFill.init();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
