export const previewDPI = 10;
export const fullDPI = 120;

const preloadImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve();
    };
    img.onerror = () => {
      resolve();
    }
  })
}

export default preloadImage;
