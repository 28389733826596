import React, { Component } from 'react';
import AdminPage from '../admin-page';
import { toLineChangeSeparated } from '../../utils'
import {Spinner} from '../../common';


import './users.scss';

export default class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      administrators: []
    }
  }

  componentDidMount() {
    this.props.isAdmin();
    this.props.fetchAdministrators();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.administrators !== this.props.administrators) {
      const lineChangeSeparatedAdministrators = toLineChangeSeparated(nextProps.administrators, 'email')
      this.setState({
        administrators: lineChangeSeparatedAdministrators
      })
    }
  }

  onChange = (e) => {
    // Because we named the inputs to match their corresponding values in state, it's easy to set the state
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  updateAdministrators = (e) => {
    e.preventDefault();
    this.props.updateAdministrators(this.state.administrators);
  }

  render() {
    const { administrators } = this.state;

    const {
      roleCheckDone
    } = this.props;

    if (!roleCheckDone) {
      return(<Spinner/>);
    }

    return (
      <AdminPage pageTitle="Ylläpitäjät" innerPage>
        <p>Tällä sivulla voit päivittää ylläpitokäyttöliittymiin pääseviä käyttäjiä sähköpostin perusteella. Huom! syötä aina koko sähköpostilista.</p>
        <p>Esim.</p>
        <pre>
          matti.meikäläinen@taike.fi<br/>
          maija.meikäläinen@taike.fi
        </pre>
        <form onSubmit={this.updateAdministrators}>
          <div className="form-group">
            <label htmlFor="administrators">Sovelluksen ylläpitäjät (yksi per rivi)</label>
            <textarea
              value={administrators}
              onChange={this.onChange}
              name="administrators"
              id="administrators"
              className="form-control"
              rows="3">
            </textarea>
          </div>
          <button type="submit" className="btn">Päivitä sovelluksen ylläpitäjät</button>
        </form>
      </AdminPage>
    )
  }
}
