import React, {Component} from 'react';
import zoomIn from './zoom-in.svg';
import zoomOut from './zoom-out.svg';

import './zoom.scss';

const zoomOutText = "Poistu zoom-näkymästä";
const zoomInText = "Avaa zoom-näkymä";
export const ZoomButton = ({onToggleZoom, isZoomOpen}) => (
  <button onClick={() => onToggleZoom()} className="zoom-button" title={`${isZoomOpen ? zoomOutText : zoomInText }`}>
    { isZoomOpen ? <img src={zoomOut} alt={zoomOutText} /> : <img src={zoomIn} alt={zoomInText} />  }
  </button>
)

export default class Zoom extends Component {

  render() {

    const {
      isZoomOpen,
      currentAttachment,
      onToggleZoom,
      currentApp
    } = this.props;

    if (isZoomOpen) {
      return (
        <section className="zoom">
          <ZoomButton isZoomOpen={isZoomOpen} onToggleZoom={onToggleZoom} />
          {currentAttachment && currentAttachment.previews && currentAttachment.previews.map((preview, index) => (
            <CurrentAttachment isZoomOpen={isZoomOpen} fileTitle={currentAttachment.title} currentApp={currentApp} page={index+1} key={index} preview={preview} />
          ))}
        </section>
      )
    }
    return null;
  }
};

export const CurrentAttachment = ({preview, currentApp, page, fileTitle, isZoomOpen}) => {
  const metadataStringOfApplication = `${currentApp.applicantName}, ${fileTitle} - Sivu ${page}`;
  return (
    <div className="attachment-container">
      <img src={preview.url} alt={metadataStringOfApplication} />
      <div className={`attachment-page-number ${isZoomOpen ? 'zoom-on' : 'zoom-off'}`}>{metadataStringOfApplication}</div>
    </div>
  )
}
