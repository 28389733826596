import React from 'react';
import ReactSpinkit from 'react-spinkit';
import {
  BootstrapTable
} from 'react-bootstrap-table';
import taikeLogo from './img/taike_vaaka.svg';

import 'react-bootstrap-table/css/react-bootstrap-table.css'; // For bs-table
import './common.scss';
import './react-bootstrap-table-custom.scss'; // For bs-table

export const Logo = () => (<img className="logo" src={taikeLogo} alt="Taiteen edistämiskeskus" />)

export const Spinner = () => (
  <div className="loading-indicator">
    <ReactSpinkit name="bars" />
  </div>
)

export const ApplicationHeader = ({ children, admin }) => (
  <header className={`application-header ${admin ? 'admin-header': 'user-header'}`}>
    { children }
    { admin && <h1 className="admin-panel-title">Hallintapaneeli</h1> }
  </header>
)

export const TaikeTable = (props) => {
  return (
    <BootstrapTable condensed {...props}>{props.children}</BootstrapTable>
  )
}
