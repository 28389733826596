import React from 'react';

import './metadata.scss';

const MetadataItem = ({label, currentApp, property}) => {
  return (
    <div className="metadata">
      <h2>{label}</h2>
      <p>{currentApp && currentApp[property] ? currentApp[property] : '?'}</p>
    </div>
  )
}

const Metadata = ({currentApp}) => {
  return (
    <aside className="metadata-container">

      <MetadataItem label="Syntymäaika" property="applicantBirthDate" currentApp={currentApp} />
      <MetadataItem label="Diaarinumero" property="journalId" currentApp={currentApp} />
      <MetadataItem label="Taiteenala" property="grantField" currentApp={currentApp} />
      <MetadataItem label="Käyttötarkoitus" property="grantType" currentApp={currentApp} />
      <MetadataItem label="Haettu summa" property="grantSize" currentApp={currentApp} />
      <MetadataItem label="Kuvaus" property="description" currentApp={currentApp} />
      </aside>
  )
}

export default Metadata;
