import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import {
  ApplicationHeader,
  Logo
} from '../common';

import './admin-page.scss';

const AdminPage = ({pageTitle, children, innerPage}) => (
  <div className="Admin-page">
    <ApplicationHeader admin>
      <Link to={'/'}><Logo /></Link>
    </ApplicationHeader>
    <section className="page-content">
      <h2 className="page-title">{ innerPage && <Link className="back-to-applications" to={'/admin'}><FontAwesome name="arrow-circle-left" /></Link> } { pageTitle }</h2>
      { children }
    </section>
  </div>
)

export default AdminPage;
