import React, { Component } from 'react';
import AdminPage from '../admin-page';
import { TaikeTable } from '../../common';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {Spinner} from '../../common';
import { API } from '../../crud';

import './reports.scss';

export default class Reports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      adminBundles: '',
      reviewerReportStartDate: '',
      reviewerReportEndDate: ''
    }
  }

  onChange = (e) => {
    // Because we named the inputs to match their corresponding values in state, it's easy to set the state
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.adminBundles !== this.props.adminBundles) {
      this.setState({
        adminBundles: nextProps.adminBundles
      })
    }
  }

  componentDidMount() {
    this.props.isAdmin();
    this.props.fetchAdminBundles();
  }

  /**
   * Periaatteessa meillä voisi olla yksi pitkäpitkä koontien listaus (jossa uusin ylinnä),
   * jossa rivi toimii linkkinä aina ko. koonnin arviointi-Exceliin.
   * Niissä voisi tosiaan olla yhteenveto arvion tilasta ehdottamallasi tavalla.
   * Toki riittää sekin, että siinä sanotaan ”10 valmista arviota”.
   * Ei tarvita välttämättä sitä, kuinka moni on kesken.
   */
  getRatingsReportFormatter = (ratingCell, row) => {
    return (
      <a className="btn btn-link btn-success" href={API(`/ratingsReport/${row.id}?format=xlsx`)}>Lataa Excel</a>
    )
  }

  toPercentageValue = (done, max) => {
    return ((done / max) * 100).toFixed(2);
  }

  reviewPercentageFormatter = (inProgressCell, row) => {
    const done = Number(row.finished);
    const max = Number(inProgressCell) + Number(row.finished);
    const progressAsPercentageValue = this.toPercentageValue(done, max);
    const title = `Arvioijista ${progressAsPercentageValue} % on antanut arvion`;
    return (
      <div className="progress" title={title}>
        <div className="progress-bar progress-bar-info" role="progressbar" aria-valuenow={progressAsPercentageValue} aria-valuemin="0" aria-valuemax="100" style={{width: `${progressAsPercentageValue}%`}} />
        <span className="sr-only">{progressAsPercentageValue} %</span>
      </div>
    )
  }

  generateReviewerReport = (e) => {
    e.preventDefault();
    this.props.generateReviewerReport(this.state.reviewerReportStartDate, this.state.reviewerReportEndDate);
  }

  render() {

    const {
      roleCheckDone
    } = this.props;

    if (!roleCheckDone) {
      return(<Spinner/>);
    }

    const {
      adminBundles,
      reviewerReportStartDate,
      reviewerReportEndDate
    } = this.state;

    const options = {
      onRowClick: this.onRowClick
    };

    return (
      <AdminPage pageTitle="Raportit" innerPage>
        <h3>Koonnit</h3>
        <p>Palaverissa hyödynnettävät raportit</p>
        <TaikeTable
          options={options}
          data={adminBundles}
          striped
          hover>
          <TableHeaderColumn width="10%" dataField="id" isKey={true} dataAlign="center" dataSort={true}>Koonti-ID</TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataSort={true}>Status</TableHeaderColumn>
          <TableHeaderColumn dataField="name" dataSort={true}>Nimi</TableHeaderColumn>
          <TableHeaderColumn dataField="type" dataSort={true}>Nimi</TableHeaderColumn>
          <TableHeaderColumn dataField="account" dataSort={true}>Tili</TableHeaderColumn>
          <TableHeaderColumn dataField="inProgress" dataFormat={ this.reviewPercentageFormatter } dataSort={true}>Valmiit arviot</TableHeaderColumn>
          <TableHeaderColumn dataField="id" dataFormat={ this.getRatingsReportFormatter } dataSort={true}>Toiminnot</TableHeaderColumn>
        </TaikeTable>
        <hr/>
        <h3>Arvioijat</h3>
        <p>Luo raportti arvioijista tietylle aikavälille</p>
        <form onSubmit={this.generateReviewerReport}>
          <div className="form-group form-inline">
            <label htmlFor="startDate">Alkaen päivämäärästä</label> <input type="date" onChange={this.onChange} value={reviewerReportStartDate} name="reviewerReportStartDate" className="form-control" id="reviewerReportStartDate" placeholder="yyyy-mm-dd" />
          </div>
          <div className="form-group form-inline">
            <label htmlFor="endDate">Päättyen (mukaanlukien viimeinen päivämäärä)</label> <input type="date" onChange={this.onChange} value={reviewerReportEndDate} name="reviewerReportEndDate" className="form-control" id="reviewerReportEndDate"  placeholder="yyyy-mm-dd" />
          </div>
          <button type="submit" className="btn">Generoi</button>
        </form>
      </AdminPage>
    )
  }
}
