const API_VERSION = '/v10';
const DEV_API_URL = 'http://localhost:3003';
const TEST_API_URL = 'https://taike-dev-lb-1354148597.eu-west-1.elb.amazonaws.com';
const PRODUCTION_API_URL = 'https://taike-arviointi.fi';

const HOSTNAME = window && window.location && window.location.hostname;

export const API_WITHOUT_VERSION = (pathAndParams) => {
  const API_URL = process.env.NODE_ENV === 'development' ? DEV_API_URL: (TEST_API_URL.match(HOSTNAME + '$')? TEST_API_URL :  PRODUCTION_API_URL);
  return API_URL + pathAndParams;
}

export const API = (pathAndParams) => {
  const API_URL = process.env.NODE_ENV === 'development' ? DEV_API_URL: (TEST_API_URL.match(HOSTNAME + '$')? TEST_API_URL :  PRODUCTION_API_URL);
  return API_URL + API_VERSION + pathAndParams;
}

export const GET = (url) => {
  return fetch(API(url), {
    credentials: 'include'
  })
}

export const POST = (url, data) => {
  return fetch(API(url), {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: data
  })
}

export const PUT = (url, data) => {
  return fetch(API(url), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
}

export const DELETE = (url, data) => {
  return fetch(API(url), {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: data
  })
}

export const PATCH = (url, data) => {
  return fetch(API(url), {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: data
  })
}
