import React, { Component } from 'react';
import AdminPage from '../admin-page';
import { toLineChangeSeparated } from '../../utils';
import {Spinner} from '../../common';

import './reviewers.scss';

export default class Reviewers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bundleReviewers: '',
      bundleSelection: ''
    }
  }

  componentDidMount() {
    this.props.isAdmin();
    this.props.fetchAdminBundles();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.bundleReviewers !== this.props.bundleReviewers) {
      this.setState({
        bundleReviewers: toLineChangeSeparated(nextProps.bundleReviewers, 'email')
      })
    }
  }

  updateBundleReviewers = (e) => {
    e.preventDefault();
    this.props.updateBundleReviewers(this.state.bundleReviewers, this.state.bundleSelection);
  }

  onBundleSelectionChange = (e) => {
    // Because we named the inputs to match their corresponding values in state, it's easy to set the state
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
    if (state.bundleSelection) {
      this.props.fetchBundleReviewers(state.bundleSelection);
    } else {
      this.setState({
        bundleReviewers: ''
      })
    }
  }

  onChange = (e) => {
    // Because we named the inputs to match their corresponding values in state, it's easy to set the state
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  render() {

    const {
      roleCheckDone,
      adminBundles
    } = this.props;

    const {
      bundleReviewers
    } = this.state;

    if (!roleCheckDone) {
      return(<Spinner/>);
    }

    return (
      <AdminPage pageTitle="Arvioijat" innerPage>
        <p>Tällä sivulla voit päivittää koontiin pääseviä käyttäjiä sähköpostin perusteella. Huom! syötä aina koko sähköpostilista.</p>
        <p>Esim.</p>
        <pre>
          matti.meikäläinen@taike.fi<br/>
          maija.meikäläinen@taike.fi
        </pre>
        <form onSubmit={this.updateBundleReviewers}>
          <div className="form-group">
            <label htmlFor="bundleSelection">Valitse koonti</label>
            { adminBundles && adminBundles.length > 0 &&
              <select onChange={this.onBundleSelectionChange} id="bundleSelection" name="bundleSelection" className="form-control">
               <option value="" defaultValue>Valitse koonti, jonka käyttäjäryhmiä haluat muuttaa</option>
               { adminBundles.map((bundle, index) => (
                  <option key={index} value={bundle.id}>{bundle.name} ({bundle.status}), {bundle.id}</option>
               ))}
              </select>
            }
            </div>
            <div className="form-group">
              <label htmlFor="bundle-access">Koontiin pääsevät käyttäjät (yksi per rivi)</label>
              <textarea
                value={bundleReviewers}
                onChange={this.onChange}
                name="bundleReviewers"
                id="bundle-reviewers"
                className="form-control"
                rows="3">
              </textarea>
            </div>
          <button type="submit" className="btn">Päivitä koonnin arvioijat</button>
        </form>
      </AdminPage>
    )
  }
}
