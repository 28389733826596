import React, {Component} from 'react';
import StarRating from 'react-star-rating-component';
import moment from 'moment';
import reviewToolsDisabledImg from './review-tools-disabled.png';
import FontAwesome from 'react-fontawesome';

import './review-tools.scss';

class ButtonWithSaveHint extends Component {
  render() {
    const {
      commentTextarea
    } = this.props;

    if (commentTextarea === '')
      return null

    return (
      <button className="btn btn-block btn-success add-comment" type="submit">Tallenna Kommentti</button>
    )
  }
}

class ReviewTools extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commentTextarea: ''
    }
  }

  updateRating = (nextValue, prevValue, name) => {
    const ratingObject = {
      applicationId: this.props.currentApp.id,
      ratingId: this.props.currentApp.ratings.id,
      ratingFieldName: name,
      ratingFieldValue: nextValue
    }
    this.props.updateRating(ratingObject);
  }

  resetRating = (name) => {
    const ratingObject = {
      applicationId: this.props.currentApp.id,
      ratingId: this.props.currentApp.ratings.id,
      ratingFieldName: name,
      ratingFieldValue: 0
    }
    this.props.updateRating(ratingObject);
  }

  addRating = (nextValue, prevValue, name) => {
    const ratingObject = {
      applicationId: this.props.currentApp.id,
      ratingFieldName: name,
      ratingFieldValue: nextValue
    }
    this.props.addRating(ratingObject);
  }

  dateToReadable = (ISO8601Date) => {
    return moment(ISO8601Date).locale('fi').format('DD.MM.YYYY hh:mm');
  }

  clearCommentTextarea = () => {
    this.setState({
      commentTextarea: ''
    })
  }

  deleteComment = (commentId) => {
    const confirmed = window.confirm("Haluatko poistaa kommentin?");
    if (confirmed) {
      this.props.deleteComment(commentId)
    }
  }

  addComment = (e) => {
    e.preventDefault();
    if (this.state.commentTextarea === '') { return; }
    this.props.addComment(this.state.commentTextarea, this.props.currentApp.id);
    this.clearCommentTextarea();
  }

  onChange = (e) => {
    // Because we named the inputs to match their corresponding values in state, it's easy to set the state
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  getRatingKey = (index) =>{
     return `rating${(index + 1)}`
   }

  render() {

    const {
      commentTextarea
    } = this.state;

    const {
      currentApp,
      isReviewable,
      ratingFields
    } = this.props;

    if (isReviewable) {
      return (
        <section className="review-tools">
          <div className="all-ratings">
            <aside>
              {ratingFields && ratingFields.map((ratingField, index) =>(
                <div key={index}>
                  <h3 className="rating-helper">Arvio: <span className="rating-subject">{ratingField[this.getRatingKey(index)]}</span></h3>
                  <div className="rating-container">
                    <Rating
                      addRating={this.addRating}
                      updateRating={this.updateRating}
                      name={this.getRatingKey(index)}
                      currentApp={currentApp} />
                    <button
                      onClick={() => this.resetRating(this.getRatingKey(index))}
                      className="btn"
                      type="submit">Nollaa</button>
                  </div>
                </div>
              ))}
            </aside>
          </div>
          <form onSubmit={this.addComment}>
            <h2>Omat kommentit</h2>
            <textarea
              value={commentTextarea}
              onChange={this.onChange}
              name="commentTextarea"
              className="comment-textarea"
              placeholder="Kirjoita tähän itsellesi näkyviä kommentteja. Muista tallentaa!">
            </textarea>
            <ButtonWithSaveHint commentTextarea={commentTextarea} />
          </form>
          <ul className="comments-list">
            { currentApp && currentApp.comments && currentApp.comments.map((c, index) => (
              <li key={index} className="comment">
                &raquo; {c.comment} <span className="comment-created">{this.dateToReadable(c.created)} <button title="Poista kommenttisi hakemukselta" onClick={() => this.deleteComment(c.id)} className="btn-delete"><FontAwesome name="trash" /> Poista</button></span>
              </li>
            ))}
          </ul>
        </section>
      )
    } else {
      return (
        <section className="review-tools-disabled">
          <h2 className="disabled-text">Muokataksesi arvioita, palauta koonti arviointi-tilaan hakemuslistassa.</h2>
          <img src={reviewToolsDisabledImg} alt="Muokataksesi arvioita, palauta koonti arviointi-tilaan hakemuslistassa." />
        </section>
      )
    }
  }
}

class Rating extends Component {

  initialValue = (currentApp, name) => {
    if (currentApp && currentApp.ratings && currentApp.ratings[name]) {
      return Number(currentApp.ratings[name])
    }
    return 0
  }

  handleOnStarClick = (currentApp, addRating, updateRating) => {
    if(currentApp && currentApp.ratings) {
      return updateRating
    }
    return addRating
  }

  render() {

    const {
      currentApp,
      addRating,
      name,
      smaller,
      updateRating
    } = this.props;

    return (
      <StarRating
        className={smaller ? 'star-rating smaller' : 'star-rating'}
        name={name} /* name of the radio input, it is required */
        value={this.initialValue(currentApp, name)} /* number of selected icon (`0` - none, `1` - first) */
        starColor={'#473178'} /* color of selected icons, default `#ffb400` */
        emptyStarColor={'#C1BFC4'} /* color of non-selected icons, default `#333` */
        editing={true} /* is component available for editing, default `true` */
        onStarClick={this.handleOnStarClick(currentApp, addRating, updateRating)} /* on icon click handler */
      />
    )
  }
}


export default ReviewTools;
