import React, {Component} from 'react';
import Tappable from 'react-tappable';
import {withRouter} from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import get from 'lodash/get'
import uniq from 'lodash/uniq'

import './attachment.scss'

class AttachmentLinks extends Component {

  mapLinksToAttachments = (currentApp, index) => {
    if (currentApp && currentApp.attachments && currentApp.links) {
      const wantedAttachmentId = currentApp.attachments[index].id;
      const result = currentApp.links.find((link) => Number(link.attachmentId) === Number(wantedAttachmentId))
      return result;
    }
    return null;
  }

  render() {
    const {
      currentApp,
      index
    } = this.props;

    const currentAttachmentLinkObject = this.mapLinksToAttachments(currentApp, index);

    if (currentAttachmentLinkObject) {
      const uniqueLinksInCurrentAttachmentObject = uniq(currentAttachmentLinkObject.links)
      return (
        <div className="attachment-links">
          <h3>Linkit:</h3>
            {uniqueLinksInCurrentAttachmentObject && uniqueLinksInCurrentAttachmentObject.map((link, key) => {
              return <p key={key}><a target="_blank" rel="noopener noreferrer" href={link}>{link}</a></p>
            })}
        </div>
      )
    }

    return null
  }
}

class Attachment extends Component {

  render() {

    const {
      pagesCountByFile,
      currentlyFocusedAttachmentIndex,
      index,
      currentApp,
      changeFocusedAttachment
    } = this.props;

    return (
      <div className={`attachment-container ${currentlyFocusedAttachmentIndex === (index) ? 'currently-focused-attachment' : ''}`}>
        <div>
          <Tappable onTap={() => changeFocusedAttachment((index))}>
            <PreviewImageWithLoadingIndicator
              src={currentApp && currentApp.attachments && currentApp.attachments[index] && currentApp.attachments[index].previews && currentApp.attachments[index].previews[0] && currentApp.attachments[index].previews[0].url}
              alt="Liite"
              currentApp={currentApp}
              pagesCountByFile={pagesCountByFile}
              />
              <AttachmentName currentApp={currentApp} index={index} />
            {pagesCountByFile && <h3>Sivuja {pagesCountByFile} kpl</h3> }
          </Tappable>
          <DownloadAttachment currentApp={currentApp} index={index} />
        </div>
        {currentApp && currentApp.attachments && currentApp.attachments[index] &&
         <AttachmentLinks currentApp={currentApp} index={index} />
        }
      </div>
    )
  }
}

export default withRouter(Attachment);

const DownloadAttachment = ({currentApp, index}) => {
  if (currentApp && currentApp.attachments && currentApp.attachments[index] && currentApp.attachments[index].url) {
    const attachmentName = get(currentApp.attachments, `[${index}].filename`, '?');
    const downloadFileName = get(currentApp.attachments, `[${index}].url`, '?');
    return (
      <h3>
        <Tappable>
          <a href={downloadFileName} download={downloadFileName} title={"Lataa omalle koneellesi tiedosto: "+attachmentName}><FontAwesome name='file-pdf-o' /> Lataa asiakirja</a>
        </Tappable>
      </h3>
    )
  }
  return <span>?</span>
}

const AttachmentName = ({currentApp, index}) => {
  const attachmentName = get(currentApp, `attachments[${index}].filename`, '?');
  return (
    <div title={attachmentName} className="attachment-name">{attachmentName}</div>
  )
}

const PreviewImageWithLoadingIndicator = ({currentApp, pagesCountByFile, src, alt}) => {
  if (!src) { // Previews can be null, if there are for example .doc attachments
    return (
      <div className="shadows no-preview">
        <span>Liitteen voi avata vain lataamalla</span>
      </div>
    )
  }
  return (
    <img className="shadows image-loaded" src={src} alt={"Liite: "+src} />
  )
}
