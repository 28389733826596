import lodashMap from 'lodash/map';

export const sortApplications = (applications, sortName, sortOrder) => {
  if (sortOrder === 'asc') {
    return applications.sort(propertyComparator(sortName));
  } else {
    return [...applications.sort(propertyComparator(sortName)).reverse()];
  }
}

const propertyComparator = (sortName) => {
  return function(a, b) {
    return safeCompare(a[sortName], b[sortName], (sortName === 'grantSize'));
  }
}

const safeCompare = (a, b, removeWhiteSpace) => {
  if (!a && a !== 0) {
    return -1;
  }

  if (!b && b !== 0) {
    return 1;
  }

  if (removeWhiteSpace) {
    a = a.replace(/\s/g, "");
    b = b.replace(/\s/g, "");
  }
  return a.toString().localeCompare(b.toString(), 'fi', {numeric: true});
}

export const toLineChangeSeparated = (array, key) => {
  return lodashMap(array, 'email').join('\r\n');
}
