import React, { Component } from 'react';
import AdminPage from '../admin-page';
import { API_WITHOUT_VERSION } from '../../crud';
import {Spinner} from '../../common';
import './scrape.scss';

const UPDATE_ALL = "updateAll"
const UPDATE_SINGLE_APPLICATION = "updateSingleApplication"

export default class Scrape extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sessionCookie: '',
      bundleID: '',
      scrapingMode: '',
      singleApplicationID: '', // This is optional, only relevant if you select
      ratingFields: [
        {rating1: 'Arvio 1'}
      ]
    }
  }

  componentDidMount() {
    this.props.isAdmin();
    this.props.fetchAdminBundles();
    this.setState({
      scrapingMode: UPDATE_ALL
    })
  }

  onChange = (e) => {
    // Because we named the inputs to match their corresponding values in state, it's easy to set the state
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  onRatingFieldChange = (e) => {
    const state = this.state
    state['ratingFields'][e.target.name][e.target.id] = e.target.value
    this.setState(state)
  }

  scrapeBundle = (e) => {
    e.preventDefault();
    const {
      sessionCookie,
      bundleID,
      scrapingMode,
      ratingFields,
      singleApplicationID
    } = this.state;
    this.props.scrapeBundle(bundleID, sessionCookie, scrapingMode, ratingFields, singleApplicationID);
  }

  addRatingField = (e) => {
    this.setState((prevState) => {
      const nextFieldNumber = prevState.ratingFields.length + 1
      return {
        ratingFields: [...prevState.ratingFields,
          {[`rating${nextFieldNumber}`]: `Arvio ${nextFieldNumber}`}
        ]}
    });
  }

  removeRatingField = (e) => {
    this.setState((prevState) => {
      let newFields = prevState.ratingFields;
      newFields.pop()
      return {ratingFields: newFields}
    });
  }

  render() {

    const {
      scrapeBundleTrackingUrl,
      roleCheckDone
    } = this.props;

    const {
      ratingFields
    } = this.state

    if (!roleCheckDone) {
      return(<Spinner/>);
    }

    return (
      <AdminPage pageTitle="Koontien käsittely" innerPage>
        <form onSubmit={this.scrapeBundle}>
          <div className="form-group">
            <label htmlFor="sessionCookie">Sessio-eväste</label>
            <input name="sessionCookie" onChange={this.onChange} type="sessionCookie" className="form-control" id="sessionCookie" placeholder="Session cookie" />
          </div>
          <div className="form-group">
            <label htmlFor="bundleID">Koonti-ID</label>
            <input name="bundleID" onChange={this.onChange} type="bundleID" className="form-control" id="bundleID" placeholder="Bundle ID" />
          </div>
          <div className="form-group">
            <fieldset>
              <legend>Haettavat koonnit</legend>
              <div>
                <label>
                  <input
                    onChange={this.onChange}
                    type="radio"
                    checked={this.state.scrapingMode === UPDATE_ALL}
                    name="scrapingMode"
                    value={UPDATE_ALL} /> Hae kaikki</label>
              </div>
              <div>
                <label>
                  <input
                    onChange={this.onChange}
                    type="radio"
                    checked={this.state.scrapingMode === "updateOnlyNewApplications"}
                    name="scrapingMode"
                    value="updateOnlyNewApplications" /> Hae uudet</label>
              </div>
              <div>
                <label>
                  <input
                    onChange={this.onChange}
                    type="radio"
                    checked={this.state.scrapingMode === UPDATE_SINGLE_APPLICATION}
                    name="scrapingMode"
                    value={UPDATE_SINGLE_APPLICATION} /> Päivitä yksittäinen hakemus</label>
              </div>
            </fieldset>
          </div>
          {this.state.scrapingMode === UPDATE_SINGLE_APPLICATION &&
            <div className="form-group">
              <label htmlFor="singleApplicationID">Yksittäinen ID</label>
              <input name="singleApplicationID" onChange={this.onChange} type="singleApplicationID" className="form-control" id="singleApplicationID" placeholder="Syötä tähän yksittäinen ID" />
            </div>
          }

          {this.state.scrapingMode === UPDATE_ALL &&
            <fieldset>
              <p>Syötä haluamasi määrä arviointikenttiä tälle koonnille (1-6). Nimeä kentät kuten haluat, kuitenkaan käyttämättä samaa nimeä kahdelle eri kentälle.</p>
              <RatingFields
                ratingFields={ratingFields}
                handleRemove={this.removeRatingField}
                handleOnChange={this.onRatingFieldChange} />
              {ratingFields.length < 6 && <button onClick={this.addRatingField} type="button" className="btn">Lisää arviointikenttä</button>}
            </fieldset>
          }

          <button type="submit" className="btn btn-start-processing">Aloita aineiston prosessointi</button>
        </form>
        <hr/>
        { scrapeBundleTrackingUrl && (
          <div>
            <p className="scrape-bundle-tracking-url">Voit seurata koonnin käsittelyä osoitteessa (avaa uuteen ikkunaan): <a href={API_WITHOUT_VERSION(scrapeBundleTrackingUrl)} target="_blank" rel="noopener noreferrer">{API_WITHOUT_VERSION(scrapeBundleTrackingUrl)}</a></p>
            <p>Huom. linkkiä täytyy päivittää, jotta näet uusimman tilan.</p>
          </div>
        )}
      </AdminPage>
    )
  }
}


class RatingFields extends Component {

  getRatingIndex = (index) => {
    return index + 1;
  }

  getRatingField = (index) => {
    return `rating${this.getRatingIndex(index)}`
  }

  render() {

    const {
      handleRemove,
      handleOnChange,
      ratingFields
    } = this.props

    return (
      <div>
        {ratingFields.map((ratingField, index, arr) => (
          <div key={index} className="form-group">
            <label
              htmlFor={ratingField[this.getRatingField(index)]}>
              {`${this.getRatingIndex(index)}. Arvioitavan ominaisuuden nimi`}
              </label>
            <div className="rating-additional-field">
              <input
                defaultValue={`Arvio ${this.getRatingIndex(index)}`}
                name={index}
                onChange={handleOnChange}
                className="form-control"
                id={this.getRatingField(index)}
                placeholder={`Arvio ${this.getRatingIndex(index)}`} />
              {arr.length - 1 === index && ratingFields.length > 1  && <button onClick={handleRemove} type="button" className="btn">Poista</button>}
            </div>
          </div>
        ))}
      </div>
    )
  }
}
