import React, { Component } from 'react';
import {
  withRouter,
  Link
} from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import {
  array, object
} from 'prop-types';
import preloadImage, {
  fullDPI
} from './preloader';
import FontAwesome from 'react-fontawesome';
import ReviewTools from './review-tools';
import Attachment from './attachment';
import Metadata from './metadata';
import {
  ApplicationHeader,
  Logo
} from '../common';
import Zoom, {ZoomButton, CurrentAttachment} from './zoom';
import './application.scss';
import {Spinner} from '../common';
import includes from 'lodash/includes';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import flexibility from 'flexibility';
flexibility(document.documentElement);

class Application extends Component {
  static propTypes = {
    applicationStubData: object.isRequired,
    allApplications: array.isRequired
  }

  static defaultProps = {
    applicationStubData: {},
    allApplications: []
  }

  constructor(props) {
    super(props);
    this.state = {
      currentlyFocusedAttachmentIndex: 0,
      isZoomOpen: false
    }
  }

  // Close zoom on back click
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (includes(prevProps.location.pathname, 'zoom')) {
        this.setState({
          isZoomOpen: false
        })
      }
    }
  }

  componentDidMount() {

    const { applicationID } = this.props.match.params;
    this.props.isLoggedIn();
    this.props.fetchApplicationByID(applicationID, fullDPI);
    this.props.fetchApplicationRatings(applicationID);
    this.props.fetchApplicationComments(applicationID);
    this.props.fetchLinksByApplicationID(applicationID)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.applicationID !== this.props.match.params.applicationID) {
      const { applicationID } = nextProps.match.params;
      this.props.resetCurrentApplication();
      this.props.fetchApplicationByID(applicationID, fullDPI);
      this.props.fetchApplicationRatings(applicationID);
      this.props.fetchApplicationComments(applicationID);
      this.props.fetchLinksByApplicationID(applicationID);
      this.setState({ currentlyFocusedAttachmentIndex: 0 });
    }

    if (nextProps.contentLoadingFinishedForPreloading) {
      if (this.props.currentApplicationFullData) {
        this.preloadImagesInBackground();
      }
    }
  }

  preloadImagesInBackground = () => {
    const {
      currentApplicationFullData
    } = this.props;

    if (currentApplicationFullData && currentApplicationFullData.attachments) {
      let allImages = [];
      currentApplicationFullData.attachments.forEach((attachment) => {
        if (attachment.previews) {
          attachment.previews.forEach((preview) => {
            allImages.push(preview.url);
          });
        }
      });

      requestIdleCallback(() => {
        Promise.all(allImages.map((image) => {
          return preloadImage(image);
        })).then(() => {
          //console.log('Pre-loaded images')
        })
      })
    }
  }

  changeFocusedAttachment = (index) => {
    this.setState({ currentlyFocusedAttachmentIndex: Number(index) });

    // Scroll to top of currentAttachment container
    document.querySelector('#scrollToCurrentAttachmentTop').scrollIntoView();
  }

  findIndexFromArray = (index, array) => {
    return findIndex(array, function(a) { return parseInt(a.id, 10) === parseInt(index, 10) });
  }

  navigateToSpecificApplicationByID = (applicationID) => {
    this.props.history.push(`/applications/${applicationID}`);
  }

  navigate = (currentApp, offset) => {
    const {
      allApplications,
      currentlyActiveFilter
    } = this.props
    let currentlyFilteredApplications = []
    if (currentlyActiveFilter && currentlyActiveFilter.grantField) {
      currentlyFilteredApplications = allApplications.filter((application) => {
        return application.grantField === currentlyActiveFilter.grantField.value
      })
    } else {
      currentlyFilteredApplications = allApplications
    }
    const currentIndex = this.findIndexFromArray(parseInt(currentApp.id, 10), currentlyFilteredApplications);
    const targetIndex = currentIndex + offset;
    const nextApplication = currentlyFilteredApplications[targetIndex];
    if (nextApplication) {
      this.navigateToSpecificApplicationByID(nextApplication.id);
    }
    this.child.clearCommentTextarea();
  }

  toggleZoom = () => {
    this.setState(prevState => ({
      isZoomOpen: !prevState.isZoomOpen
    }))
  }

  getRatingFields = (bundleAlternatives, currentBundleId) => {
    const currentBundle = bundleAlternatives.find(b => b.id === currentBundleId)
    if (currentBundle) {
      return currentBundle.ratingsConf.ratingFields
    }
    return null
  }

  render() {

    const {
      currentlyFocusedAttachmentIndex,
      isZoomOpen
    } = this.state;

    const {
      currentApplicationFullData,
      updateRating,
      addRating,
      addComment,
      deleteComment,
      loginDone,
      bundleAlternatives,
      currentBundleId,
      isReviewable
    } = this.props;

    if (!loginDone) {
      return(<Spinner/>);
    }

    return (
      <div className="Application">
        <ApplicationHeader>
          <div className="context">
            <Link to={'/'}><Logo /></Link>
          </div>
          <Link className="back-to-applications" to={'/'}><FontAwesome name="arrow-circle-left" /> Hakemuslistaan</Link>
          <aside className="btn-group next-and-previous" role="group" aria-label="Navigaatio">
            <button onClick={() => this.navigate(currentApplicationFullData, -1)} className="btn btn-reverse" type="button"><FontAwesome name='step-backward' /> <span className="hidden-sm hidden-xs">Edellinen</span></button>
            <button onClick={() => this.navigate(currentApplicationFullData, 1)} className="btn btn-reverse" type="button"><span className="hidden-sm hidden-xs">Seuraava</span> <FontAwesome name='step-forward' /></button>
          </aside>
        </ApplicationHeader>
        {!isEmpty(this.props.currentlyActiveFilter) && <span className="currently-active-filter">Taiteenala: {get(this.props.currentlyActiveFilter, 'grantField.value', '?')}</span>}
        {currentApplicationFullData && currentApplicationFullData.attachments && (
          <Link to={`/applications/${currentApplicationFullData.id}`}>
            <Zoom
              onToggleZoom={this.toggleZoom}
              isZoomOpen={isZoomOpen}
              currentAttachment={currentApplicationFullData.attachments[currentlyFocusedAttachmentIndex]}
              currentApp={currentApplicationFullData} />
          </Link>
          )
        }
        <section className="application-content">
          <section className="all-attachments">
            <header className="attachment-count">
              <span>Asiakirjoja {currentApplicationFullData && currentApplicationFullData.attachments && currentApplicationFullData.attachments.length} kpl</span>
            </header>
            { currentApplicationFullData && currentApplicationFullData.attachments && currentApplicationFullData.attachments.map((attachment, index) => (
              <Attachment
                key={index}
                index={index}
                pagesCountByFile={attachment.previews && attachment.previews.length}
                currentlyFocusedAttachmentIndex={currentlyFocusedAttachmentIndex}
                changeFocusedAttachment={this.changeFocusedAttachment}
                currentApp={currentApplicationFullData} />
            ))}
          </section>
          <section className={`current-attachment ${isZoomOpen ? 'zoom-on' : 'zoom-off'}`}>
            <div id="scrollToCurrentAttachmentTop" />
            {currentApplicationFullData &&
              <Link to={`/applications/${currentApplicationFullData.id}/zoom`}>
                <ZoomButton isZoomOpen={isZoomOpen} onToggleZoom={this.toggleZoom} />
              </Link>
            }
            {currentApplicationFullData && currentApplicationFullData.attachments && currentApplicationFullData.attachments[currentlyFocusedAttachmentIndex] && currentApplicationFullData.attachments[currentlyFocusedAttachmentIndex].previews && currentApplicationFullData.attachments[currentlyFocusedAttachmentIndex].previews.map((preview, index) => (
              <CurrentAttachment
                isZoomOpen={isZoomOpen}
                fileTitle={currentApplicationFullData.attachments[currentlyFocusedAttachmentIndex].title}
                currentApp={currentApplicationFullData}
                page={index+1}
                key={index}
                preview={preview} />
            ))}
          </section>
          <section className="sidebar">
            <Metadata currentApp={currentApplicationFullData} />
            <ReviewTools
              ref={instance => { this.child = instance; }}
              isReviewable={isReviewable}
              currentApp={currentApplicationFullData}
              updateRating={updateRating}
              addRating={addRating}
              addComment={addComment}
              ratingFields={this.getRatingFields(bundleAlternatives, currentBundleId)}
              deleteComment={deleteComment} />
          </section>
        </section>
      </div>
    )
  }
}

export default withRouter(Application);
