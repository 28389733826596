import React, {Component} from 'react';
import {
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import ApplicationList from './application-list';
import Application from './application';
import AdminReviewers from './admin/reviewers';
import AdminScrape from './admin/scrape';
import AdminApplications from './admin/applications';
import AdminReports from './admin/reports';
import AdminUsers from './admin/users';
import AdminIndex from './admin';
import Error from './error';
import {
  setReviewableMode,
  getBundleAlternatives,
  getAllApplicationsByBundleID,
  getApplicationByID,
  getApplicationRatings,
  getApplicationComments,
  getLinksByApplicationID,
  resetCurrentApplication,
  updateRating,
  addRating,
  deleteComment,
  addComment,
  deleteApplicationByID,
  deleteBundleByID,
  updateOrderOfAllApplications,
  filterApplicationsByKeyword,
  fetchAdminBundles,
  updateBundleReviewers,
  scrapeBundle,
  updateAdministrators,
  displayAllApplicationsFromCurrentBundle,
  fetchBundleReviewers,
  fetchAdministrators,
  generateReviewerReport,
  setCurrentlyActiveFilterForApplications,
  isAdmin,
  isLoggedIn
} from './redux';

import 'ric'; // requestIdleCallback Polyfill

import './app.scss';

const mapStateToProps = (state) => {
  return {
    isReviewable: state.isReviewable,
    bundleAlternatives: state.bundleAlternatives,
    currentBundleId : state.currentBundleId,
    allApplications: state.allApplications,
    currentApplicationFullData: state.currentApplication,
    adminBundles: state.adminBundles,
    administrators: state.administrators,
    bundleReviewers: state.bundleReviewers,
    allApplicationsLoading: state.allApplicationsLoading,
    contentLoadingFinishedForPreloading: state.contentLoadingFinishedForPreloading,
    scrapeBundleTrackingUrl: state.scrapeBundleTrackingUrl,
    recentlyDeleted: state.recentlyDeleted,
    roleCheckDone: state.roleCheckDone,
    loginDone: state.loginDone,
    grants: state.grants,
    currentlyActiveFilter: state.currentlyActiveFilter
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBundleAlternatives: () => {
      dispatch(getBundleAlternatives());
    },
    fetchAllApplicationsByBundleID: (bundleID) => {
      dispatch(getAllApplicationsByBundleID(bundleID));
    },
    fetchLinksByApplicationID: (applicationID) => {
      dispatch(getLinksByApplicationID(applicationID))
    },
    fetchApplicationByID: (applicationID, DPI) => {
      dispatch(getApplicationByID(applicationID, DPI));
    },
    deleteApplicationByID: (applicationID) => {
      dispatch(deleteApplicationByID(applicationID));
    },
    deleteBundleByID: (bundleID) => {
      dispatch(deleteBundleByID(bundleID))
    },
    fetchApplicationRatings: (applicationID) => {
      dispatch(getApplicationRatings(applicationID));
    },
    fetchApplicationComments: (applicationID) => {
      dispatch(getApplicationComments(applicationID));
    },
    onResetCurrentApplication: () => {
      dispatch(resetCurrentApplication());
    },
    updateRating: (rating) => {
      dispatch(updateRating(rating));
    },
    addRating: (rating) => {
      dispatch(addRating(rating));
    },
    deleteComment: (commentId) => {
      dispatch(deleteComment(commentId))
    },
    addComment: (comment, applicationID) => {
      dispatch(addComment(comment, applicationID))
    },
    updateOrderOfAllApplications: (sortName, sortOrder) => {
      dispatch(updateOrderOfAllApplications(sortName, sortOrder))
    },
    filterApplicationsByKeyword: (keyword) => {
      dispatch(filterApplicationsByKeyword(keyword));
    },
    fetchAdminBundles: () => {
      dispatch(fetchAdminBundles());
    },
    updateBundleReviewers: (bundleReviewers, bundleSelection) => {
      dispatch(updateBundleReviewers(bundleReviewers, bundleSelection))
    },
    scrapeBundle: (bundleID, sessionCookie, scrapingMode, ratingFields, singleApplicationID) => {
      dispatch(scrapeBundle(bundleID, sessionCookie, scrapingMode, ratingFields, singleApplicationID))
    },
    updateAdministrators: (administrators) => {
      dispatch(updateAdministrators(administrators));
    },
    fetchBundleReviewers: (bundleSelection) => {
      dispatch(fetchBundleReviewers(bundleSelection));
    },
    fetchAdministrators: () => {
      dispatch(fetchAdministrators());
    },
    isAdmin: () => {
      dispatch(isAdmin());
    },
    isLoggedIn: () => {
      dispatch(isLoggedIn());
    },
    setReviewableMode: (isReviewable, currentBundleId) => {
      dispatch(setReviewableMode(isReviewable, currentBundleId));
    },
    setCurrentlyActiveFilterForApplications: (filterObj) => {
      dispatch(setCurrentlyActiveFilterForApplications(filterObj))
    },
    generateReviewerReport: (startDate, endDate) => {
      dispatch(generateReviewerReport(startDate, endDate))
    },
    displayAllApplicationsFromCurrentBundle: () => {
      dispatch(displayAllApplicationsFromCurrentBundle())
    }
  }
}

class App extends Component {
  onBundleChange = (e) => {
    const selectedBundleID = e.target.value;
    this.props.fetchAllApplicationsByBundleID(selectedBundleID);
  }

  componentDidMount() {
    this.props.fetchBundleAlternatives();
  }

  render() {

    const {
      isReviewable,
      bundleAlternatives,
      allApplications,
      fetchApplicationByID,
      currentApplicationFullData,
      fetchApplicationRatings,
      fetchApplicationComments,
      setCurrentlyActiveFilterForApplications,
      fetchLinksByApplicationID,
      onResetCurrentApplication,
      displayAllApplicationsFromCurrentBundle,
      updateOrderOfAllApplications,
      updateRating,
      currentlyActiveFilter,
      currentBundleId,
      addRating,
      addComment,
      filterApplicationsByKeyword,
      fetchAdminBundles,
      adminBundles,
      updateBundleReviewers,
      scrapeBundle,
      updateAdministrators,
      fetchBundleReviewers,
      fetchAdministrators,
      administrators,
      bundleReviewers,
      allApplicationsLoading,
      contentLoadingFinishedForPreloading,
      scrapeBundleTrackingUrl,
      roleCheckDone,
      loginDone,
      deleteComment,
      deleteApplicationByID,
      deleteBundleByID,
      recentlyDeleted,
      isAdmin,
      isLoggedIn,
      generateReviewerReport,
      grants,
      setReviewableMode
    } = this.props;

    return (
      <div className="App">
        <Switch>
          <Route exact path="/" render={(props, state) => (
            <ApplicationList
              {...props}
              isReviewable={isReviewable}
              setReviewableMode={setReviewableMode}
              isLoggedIn={isLoggedIn}
              loginDone={loginDone}
              allApplicationsLoading={allApplicationsLoading}
              allApplications={allApplications}
              bundleAlternatives={bundleAlternatives}
              updateOrderOfAllApplications={updateOrderOfAllApplications}
              displayAllApplicationsFromCurrentBundle={displayAllApplicationsFromCurrentBundle}
              filterApplicationsByKeyword={filterApplicationsByKeyword}
              setCurrentlyActiveFilterForApplications={setCurrentlyActiveFilterForApplications}
              currentBundleId={currentBundleId}
              grants={grants}
              onBundleChange={this.onBundleChange} />
          )}/>
          <Route path={`/applications/:applicationID`} render={(props) => (
            <Application
              {...props}
              bundleAlternatives={bundleAlternatives}
              currentBundleId={currentBundleId}
              isReviewable={isReviewable}
              isLoggedIn={isLoggedIn}
              loginDone={loginDone}
              fetchApplicationByID={fetchApplicationByID}
              fetchApplicationRatings={fetchApplicationRatings}
              fetchApplicationComments={fetchApplicationComments}
              resetCurrentApplication={onResetCurrentApplication}
              updateRating={updateRating}
              addRating={addRating}
              currentlyActiveFilter={currentlyActiveFilter}
              deleteComment={deleteComment}
              addComment={addComment}
              fetchLinksByApplicationID={fetchLinksByApplicationID}
              currentApplicationFullData={currentApplicationFullData}
              contentLoadingFinishedForPreloading={contentLoadingFinishedForPreloading}
              allApplications={allApplications} />
              )} />
          ))}
          <Route path={`/applications/:applicationID/zoom`} render={(props) => (
            <Application
              {...props}
              bundleAlternatives={bundleAlternatives}
              currentBundleId={currentBundleId}
              isReviewable={isReviewable}
              isLoggedIn={isLoggedIn}
              loginDone={loginDone}
              fetchApplicationByID={fetchApplicationByID}
              fetchApplicationRatings={fetchApplicationRatings}
              fetchApplicationComments={fetchApplicationComments}
              resetCurrentApplication={onResetCurrentApplication}
              currentlyActiveFilter={currentlyActiveFilter}
              fetchLinksByApplicationID={fetchLinksByApplicationID}
              updateRating={updateRating}
              addRating={addRating}
              addComment={addComment}
              currentApplicationFullData={currentApplicationFullData}
              contentLoadingFinishedForPreloading={contentLoadingFinishedForPreloading}
              allApplications={allApplications} />
            )} />
          ))}
          <Route exact path="/admin/" render={(props) => (
            <AdminIndex {...props}
            isAdmin={isAdmin}
            roleCheckDone={roleCheckDone} />
            )} />
          <Route path="/admin/reviewers" render={(props) => (
            <AdminReviewers
              {...props}
              isAdmin={isAdmin}
              roleCheckDone={roleCheckDone}
              fetchAdminBundles={fetchAdminBundles}
              fetchBundleReviewers={fetchBundleReviewers}
              bundleReviewers={bundleReviewers}
              updateBundleReviewers={updateBundleReviewers}
              adminBundles={adminBundles} />
            )} />
          <Route path="/admin/reports" render={(props) => (
            <AdminReports
              {...props}
              isAdmin={isAdmin}
              generateReviewerReport={generateReviewerReport}
              fetchAdminBundles={fetchAdminBundles}
              adminBundles={adminBundles}
              roleCheckDone={roleCheckDone} />
            )} />
          <Route path="/admin/scrape" render={(props) => (
            <AdminScrape
              {...props}
              isAdmin={isAdmin}
              roleCheckDone={roleCheckDone}
              fetchAdminBundles={fetchAdminBundles}
              scrapeBundle={scrapeBundle}
              scrapeBundleTrackingUrl={scrapeBundleTrackingUrl}
              adminBundles={adminBundles} />
            )} />
          <Route path="/admin/applications" render={(props) => (
            <AdminApplications
              {...props}
              isAdmin={isAdmin}
              roleCheckDone={roleCheckDone}
              deleteApplicationByID={deleteApplicationByID}
              deleteBundleByID={deleteBundleByID}
              recentlyDeleted={recentlyDeleted} />
            )} />
          <Route path="/admin/users" render={(props) => (
            <AdminUsers
              {...props}
              isAdmin={isAdmin}
              roleCheckDone={roleCheckDone}
              administrators={administrators}
              fetchAdministrators={fetchAdministrators}
              updateAdministrators={updateAdministrators} />
            )} />
          <Route path={`/error`} component={Error} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
